import Vue from 'vue';
import 'vuetify/dist/vuetify.min.css';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  iconfont: 'mdi',
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#3e8094',
        secondary: '#6cc5de',
        tertiary: '#ffffff',
        accent: '#79d0e8',
        accent2: '#758b91',
        error: '#ca4550',
        warning: '#FC703D',
        orange: '#EA8156',
        purple: '#824687',
        info: '#79d0e8',
        red: '#ca4550',
        pink: '#fd8993',
        success: '#62DEB4',
        green: '#62DEB4',
        darkgreen: '#479179',
        menu: '#000000',
        white: '#ffffff',
        offwhite: '#f5f5f5',
        grey: '#707070',
        peach: '#f5eee3'
      },
    },
  },
});
